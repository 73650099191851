
import { Component, Vue } from 'vue-property-decorator';
import { mdiEye, mdiEyeOff } from '@mdi/js';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

@Component({})
export default class CloseAccount extends Vue {
  password = null;
  visible = false;
  icons = {
    mdiEye: mdiEye,
    mdiEyeOff: mdiEyeOff
  };
  ActiveTenant = ActiveTenant;
  currentTenant = tenants[ActiveTenant];

  get appendIcon(): string | null {
    return this.visible ? mdiEye : mdiEyeOff;
  }

  get type(): string {
    return this.visible ? 'text' : 'password';
  }

  onToggleVisibility(): void {
    this.visible = !this.visible;
  }
}
